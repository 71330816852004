import { Typography } from "@bbnpm/bb-ui-framework";
import React from 'react';
import './downloads.min.css';


const Client = () => {
    const SITE_ADDRESS = process.env.PUBLIC_URL + '/';
    return (
<div className="client">
   <div className="help-body">
      <div className='content'>
         <Typography.PageTitle className="page-title">BBA Downloads</Typography.PageTitle>
         <div id="section_contact" className="help-section">
            <div className="help-section-title">Windows</div>
            <div className="help-section-content contact-section">
               <h1>Citrix Workspace App</h1>
               <h2>Installation</h2>
               <div className="sub-section-content">
               <span>Download Citrix Workspace App {window.SETTINGS.citrixInfo.windows.version} <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/windows"}>here</a></span>
               <br/>
               {window.SETTINGS.citrixInfo.windows.citrix_url &&
               <span>Alternately, you can download the client from <a class="white-link" href={window.SETTINGS.citrixInfo.windows.citrix_url} target="_blank">Citrix</a></span>}
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 1</span>
                  </div>
                  <div>
                     <span>Click 'Keep/Save' when prompted by the download.</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 2</span>
                  </div>
                  <div>
                     <span>After download is complete, click on the file to begin installation.</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 3</span>
                  </div>
                  <div>
                     <span>After plugin installation, restart your browser.</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 4</span>
                  </div>
                  <div>
                     <span>Once completed, go to the <a class="white-link" href="/">Bloomberg Anywhere login page.</a></span>
                  </div>
               </div>
            </div>
            <div class="section" id="help-link-parent">
               <a class="help-link font-medium" href="/help#section_windows" target="_blank">Need more help?</a>
            </div>
         </div>
         <div id="section_contact" className="help-section">
            <div className="help-section-title">Mac</div>
            <div className="help-section-content contact-section">
               <h1>Citrix Workspace App</h1>
               <h2>Installation</h2>
               <div className="sub-section-content">
                    <span>Download Citrix Workspace App {window.SETTINGS.citrixInfo.mac.version} <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/mac"}>here</a></span>
                    <br/>
                    {window.SETTINGS.citrixInfo.mac.citrix_url &&
                    <span>Alternately, you can download the client from <a class="white-link" href={window.SETTINGS.citrixInfo.mac.citrix_url} target="_blank">Citrix</a></span>}
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 1</span>
                  </div>
                  <div>
                     <span>Once the file has downloaded, close all web browsers.</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 2</span>
                  </div>
                  <div>
                     <span>Launch "CitrixReceiver.dmg"</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 3</span>
                  </div>
                  <div>
                     <span>If you have an existing version choose "Uninstall Citrix Receiver", otherwise, choose the install option and follow the prompts.</span>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>Step 4</span>
                  </div>
                  <div>
                     <span>Once completed, go to the <a class="white-link" href="/">Bloomberg Anywhere login page.</a></span>
                  </div>
               </div>
            </div>
            <div class="section" id="help-link-parent">
               <a class="help-link font-medium" href="/help#section_mac" target="_blank">Need more help?</a>
            </div>
         </div>
         <div id="section_contact" className="help-section">
            <div className="help-section-title">Linux</div>
            <div className="help-section-content contact-section">
               <h1>Citrix Workspace App</h1>
               <h2>Installation</h2>
               <div className="sub-section-content">
                     {window.SETTINGS.citrixInfo.linux.citrix_url &&
                     <span>Choose a client below, or visit <a class="white-link" href={window.SETTINGS.citrixInfo.linux.citrix_url} target="_blank">Citrix</a> for more downloads.</span>}
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>For x86 Systems</span>
                  </div>
                  <div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_rpm_x86"}>Receiver for Linux (.rpm)</a>
                     </div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_deb_x86"}>Receiver for Linux (.deb)</a>
                     </div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_tar_x86"}>Receiver for Linux (.tar.gz)</a>
                     </div>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>For 64-bit Systems</span>
                  </div>
                  <div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_rpm_64"}>Receiver for Linux (.rpm)</a>
                     </div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_deb_64"}>Receiver for Linux (.deb)</a>
                     </div>
                     <div>
                        <a class="white-link" href={SITE_ADDRESS + "downloads/citrix/linux_tar_64"}>Receiver for Linux (.tar.gz)</a>
                     </div>
                  </div>
               </div>
               <div className="sub-section-content">
                  <div class="install_step_title">
                     <span>* All clients require OpenMotif v.2.3.1</span>
                  </div>
               </div>
            </div>
            <div class="section" id="help-link-parent">
               <a class="help-link font-medium" href="/help#section_linux" target="_blank">Need more help?</a>
            </div>
            <br/>
         </div>
      </div>
   </div>
</div>
    )
}

export default Client;
